import * as liabilityActionTypes from 'actions/liabilityActionTypes';
import * as individualLiabilityActionTypes from 'actions/individualLiabilityActionTypes';

import update from 'immutability-helper';
import {
  setWorkingValue,
  clearWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  setNewEntities,
  insertNewEntities,
  setWorkingClientIds,
  setError,
} from 'lib/reducerHelper';

import _ from 'lodash';

import { handleActions } from 'redux-actions';
import {
  theOnlyType,
  LIABILITY_CARD_CATEGORY,
} from 'shared/constants/myCRMTypes/liabilities';
import { MONTHLY } from 'shared/constants/options';
import { unformatCurrency } from 'shared/lib/numbrero';
import { creditCardMonthlyRepaymentCalculator } from 'shared/lib/repaymentCalculator';

const actionTypes = {
  ...liabilityActionTypes,
  ...individualLiabilityActionTypes,
};
const newLiability = {
  clientIds: [],
  errors: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newLiability,
  },
};

const liabilityReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_LIABILITY](state, action) {
      const resetLiability = {
        ...newLiability,
        clientIds: state.working.new.clientIds,
      };
      return clearWorking(state, action, resetLiability);
    },
    [actionTypes.LOAD_LIABILITY](state, action) {
      return loadEntityIntoWorking(state, action, newLiability);
    },
    [actionTypes.REMOVE_LIABILITY](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_LIABILITY](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_LIABILITIES](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.INSERT_NEW_LIABILITIES](state, action) {
      return insertNewEntities(state, action);
    },
    [actionTypes.SET_LIABILITY](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_LIABILITY_CLIENT_IDS](state, action) {
      return setWorkingClientIds(state, action);
    },
    [actionTypes.SET_LIABILITY_TYPE](state, action) {
      return setWorkingValue(state, action.payload, 'type', _.toNumber);
    },
    [actionTypes.SET_LIABILITY_CATEGORY](state, action) {
      const { id, value } = action.payload;
      const category = _.toNumber(value);
      const defaultType = theOnlyType[category];
      const newWorking = {
        category: { $set: category },
      };
      if (category === LIABILITY_CARD_CATEGORY.id) {
        newWorking.repaymentAmount = {
          $set: creditCardMonthlyRepaymentCalculator(
            state.working[id].totalCardLimit,
          ),
        };
        newWorking.repaymentFrequency = { $set: MONTHLY };
      }
      if (defaultType) {
        newWorking.type = { $set: defaultType };
      }
      return update(state, { working: { [id]: newWorking } });
    },
    [actionTypes.SET_LIABILITY_DESCRIPTION](state, action) {
      return setWorkingValue(state, action.payload, 'description');
    },
    [actionTypes.SET_LIABILITY_TOTAL_CARD_LIMIT](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'totalCardLimit',
        unformatCurrency,
      );
    },
    [actionTypes.SET_LIABILITY_ORIGINAL_LOAN_AMOUNT](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'originalLoanAmount',
        unformatCurrency,
      );
    },
    [actionTypes.SET_LIABILITY_CURRENT_INTEREST_RATE](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'currentInterestRate',
        unformatCurrency,
      );
    },
    [actionTypes.SET_LIABILITY_REPAYMENT_AMOUNT](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'repaymentAmount',
        unformatCurrency,
      );
    },
    [actionTypes.SET_LIABILITY_TOTAL_LOAN_AMOUNT](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'totalLoanAmount',
        unformatCurrency,
      );
    },
    [actionTypes.SET_LIABILITY_REPAYMENT_FREQUENCY](state, action) {
      return setWorkingValue(state, action.payload, 'repaymentFrequency');
    },
    [actionTypes.SET_LIABILITY_REMAINING_YEARS](state, action) {
      return setWorkingValue(state, action.payload, 'remainingYears');
    },
    [actionTypes.SET_LIABILITY_REMAINING_MONTHS](state, action) {
      return setWorkingValue(state, action.payload, 'remainingMonths');
    },
    [actionTypes.SET_LIABILITY_CREDITOR_ID](state, action) {
      const text = action.payload.value.text || action.payload.value;
      return setWorkingValue(
        state,
        { id: action.payload.id, value: text },
        'creditorId',
      );
    },
    [actionTypes.SET_LIABILITY_CREDITOR](state, action) {
      const newWorking = {
        creditorId: { $set: action.payload.value.key || 0 },
        creditorName: { $set: action.payload.value.text },
      };
      return update(state, { working: { [action.payload.id]: newWorking } });
    },
    [actionTypes.SET_LIABILITY_IS_INTEREST_TAX_DEDUCTIBLE](state, action) {
      return setWorkingValue(state, action.payload, 'isInterestTaxDeductible');
    },
    [actionTypes.SET_LIABILITY_TO_REFINANCE](state, action) {
      return setWorkingValue(state, action.payload, 'isRefinance');
    },

    [actionTypes.SET_LIABILITY_ERROR](state, action) {
      return setError(state, action);
    },
  },
  initialState,
);

export default liabilityReducer;
